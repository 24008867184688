@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Avenir', Sans-Serif;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}
